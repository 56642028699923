/* src/components/ProductShowcase.css */
.product-showcase {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 35px;
    padding: 20px;
    margin-left: 2%;
   }
   
   .product-card {
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
   }
   
   .product-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
   }
   
   .product-image {
    width: 100%;
    height: 210px;
    object-fit: cover;
    
   }
   
   .product-info {
    padding: 15px;
   }
   
   .product-info h3 {
    margin-bottom: 10px;
    font-size: 1.2rem;
   }
   
   .product-info p {
    margin-bottom: 15px;
    font-size: 1rem;
    color: #4b4b4b;
   }
   
   .view-details-button {
    background-color: #ff6347; /* Tomato color */
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
   }
   
   .view-details-button:hover {
    background-color: #ff4500; /* Darker tomato color on hover */
   }
   
   @media (max-width: 768px) {
    .product-showcase {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Adjust column size for mobile */
        gap: 20px; /* Smaller gap for mobile */
        padding: 10px; /* Smaller padding for mobile */
        margin-left: 1%; /* Smaller margin for mobile */
    }

    .product-image {
        height: 150px; /* Adjust image height for mobile */
    }
}