/* src/components/MainBanner.css */
.main-banner {
    background-image: url('/public/photos/Luaj_Banner_1.png');
    background-size: cover;
    background-position: center;
    height: 80vh; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    text-align: center;
    
   }
   
   .banner-content {
    max-width: 800px;
    margin: 0 auto;
   }
   
   .banner-content h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
   }
   
   .banner-content p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
   }
   
   .cta-button {
    background-color: #ff6347; /* Tomato color */
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
   }
   
  @media (max-width: 768px) {
  .main-banner {
   background-image: url('/public/photos/Luaj_Banner_MobileView.png');
    height: 50vh; 
  }

  .banner-content h1 {
    font-size: 28px; 
  }

  .banner-content p {
    font-size: 17px; 
  }
}

   
   