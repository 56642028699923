/* src/components/Header.css */
.header {
  background-color: #ff6347;
  padding: 10px 0;
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
.name-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.name-header-v2{
  margin-right: 10% 
}
.name-header a {
  font-family: "Honk", system-ui;
  color: #ffffff;
  text-decoration: none;
  font-size: 44px;
}

.header-nav-links ul {
  font-family: "Kanit", sans-serif;
  list-style: none;
  display: flex;
  font-size: 19px;
  margin: 0;
  padding: 0;
}

.header-nav-links ul li {
  margin-right: 20px;
}

.header-nav-links ul li:last-child {
  margin-right: 0;
}

.header-nav-links ul li a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.header-nav-links ul li a:hover {
  color: #080808;
}

.search-bar input {
  padding: 10px 15px;
  border-radius: 12px;
  border: 2px solid #ffffff;
  color: black;
  font-size: 16px;
  transition: all 0.3s ease;
}

.search-bar input:focus {
  border-color: #000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  outline: none;
}

.search-bar input::placeholder {
  color: #141414cc;
}

.search-bar input:hover {
  background-color: #f8e5e2;
}

.search-bar {
  position: relative;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #c0c0c0;
  border-top: none;
  border-radius:12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding: 0;
  margin: 0;
  list-style: none;

}

.search-results li {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.search-results li:hover {
  background-color: #f5f5f5;
}

.search-result-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .header-container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .name-header {
    order: -1;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .header-nav-links {
    order: 1;
    width: 100%;
    margin-bottom: 10px;
  }

  .header-nav-links ul {
    justify-content: center;
  }

  .search-bar {
    order: 2;
    width: 100%;
  }
}
