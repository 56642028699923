/* src/components/ProductBanner.css */
.product-banner {
   flex: 1 1 300px; /* Allow banners to grow and shrink, with a base width of 300px */
   max-width: 600px;
   display: flex;
   flex-direction: column;
   align-items: center;
   background-color: #f8f9fa;
   padding: 20px;
   margin: 20px 0;
   border-radius: 10px;
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
   transition: all 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .banner-image-wrapper {
   position: relative;
   width: 70%;
   padding-top: 100%; /* This will maintain a 1:1 aspect ratio */
   
  }
  
  .banner-image-wrapper::before {
   content: "";
   display: block;
   padding-top: 100%;
  }
  
  .banner-image {
   position: absolute;
   top: 0;
   left: 0;
   width: 80%;
   height: 80%;
   object-fit: cover; /* Changed from 'contain' to 'cover' for a more visually appealing result */
   border-radius: 10px;
   border: none; /* Ensure no border */
   margin-bottom: 20px; /* Add some space between the image and content */
  }
  
  .banner-content {
   text-align: center;
  }
  
  .banner-content h2 {
   font-size: 1.5rem;
   margin-bottom: 10px;
  }
  
  .banner-content p {
   font-size: 1rem;
   margin-bottom: 20px;
  }
  
  .cta-button {
   background-color: #ff6347; /* Tomato color */
   color: #ffffff;
   padding: 10px 20px;
   border: none;
   border-radius: 5px;
   cursor: pointer;
   transition: background-color 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .cta-button:hover {
   background-color: #ff4500; /* Darker tomato color on hover */
  }
  
 /* src/components/ProductBanner.css */
.product-banner {
   display: flex;
   flex-direction: column;
   align-items: center;
   background-color: #f8f9fa;
   padding: 20px;
   margin: 20px 0;
   border-radius: 10px;
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
   transition: all 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .banner-image-wrapper {
   position: relative;
   width: 100%;
   padding-top: 100%; /* This will maintain a 1:1 aspect ratio */
  }
  
  .banner-image-wrapper::before {
   content: "";
   display: block;
   padding-top: 100%;
  }
  
  .banner-image {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   object-fit: cover; /* Ensures the image covers the area without losing its aspect ratio */
   border-radius: 10px;
   border: none; /* Ensure no border */
   margin-bottom: 20px; /* Add some space between the image and content */
  }
  
  .product-image img {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   object-fit: cover; /* Ensures the image covers the area without losing its aspect ratio */
 }
  /* Adjustments for phone view */
  @media (max-width: 768px) {
   .product-banner {
      flex-direction: column;
      align-items: center;
   }
  
   .banner-image-wrapper {
      width: 100%;
      padding-top: 100%;
       /* Maintains 1:1 aspect ratio on mobile */
   }
  
   .banner-image {
      width: 100%;
      height: 100%;
      object-fit: cover; /* Ensures the image covers the area without losing its aspect ratio */
   }
   .product-banner {
      padding: 10px;
    }
  
    .banner-image-wrapper {
      padding-top: 56.25%; 
    }
  
    .banner-content h2 {
      font-size: 18px;
    }
  
    .banner-content p {
      font-size: 14px;
    }
  }
  
  