/* Add to the global styles, not directly inside Footer.css */
html, body {
   height: 100%; /* Make sure html and body are at least the height of the viewport */
   margin: 0; /* Remove default margin */
 }
 
 #root { /* Assuming your app is mounted inside a div with id 'root' */
   display: flex;
   flex-direction: column;
   min-height: 100%;
 }
 
 .footer {
   background-color: #fe5b3e;
   color: #ffffff;
   padding: 0.5vw 0;
   flex-shrink: 0; /* Prevents the footer from shrinking */
   width: 100%;
   margin-top: auto; /* Pushes the footer to the bottom */
 }
 
 /* Your existing footer styles */
 .footer-container {
   display: flex;
   justify-content: space-around;
   flex-wrap: wrap;
   padding: 0 20px;
   align-items: baseline;
 }
 
 .footer-section {
   flex: 1;
   margin: 20px;
 }
 
 .footer-section h2 {
   margin-bottom: 10px;
 }
 
 .footer-section p {
   margin-bottom: 10px;
 }
 
 .social-links {
   margin: 20px;
 }
 
 .social-links a {
   color: #ffffff;
   margin-right: 10px;
   text-decoration: none;
   margin-left: 1.5%;
 }
 
 .social-links a:hover {
   box-shadow: 0 1px 3px rgba(253, 155, 99, 0.932);
   transition: transform 0.4s ease;
 }
 
 .footer-bottom {
   text-align: center;
   padding: 1px 0;
   border-top: 1px solid #ffffff;
   color: #000000;
 }
 
 @media (max-width: 768px) {
   .footer-container {
     flex-direction: column;
     align-items: center; /* Center align the content for better mobile presentation */
     padding: 20px; /* Increase padding for better spacing on small screens */
   }
 
   .footer-section {
     margin: 10px 0; /* Reduce margin between sections for compact layout */
     text-align: center; /* Center text for a cleaner look on mobile */
   }
 
   .social-links {
     justify-content: center; /* Center social media icons */
     margin: 10px 0; /* Adjust margin for better spacing */
   }
 
   .social-links a {
     margin: 0 10px; /* Provide sufficient space between icons */
   }
 
   .footer-bottom {
     padding: 10px 0; /* Increase padding for better touch interaction */
   }
 }